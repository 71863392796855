<template>
  <div class="news-content row">
    <div class="col-2"></div>
    <div class="col-8">
    <h1>News</h1>
    <ul class="news">
      <div v-for="record in records" :key="record.id">
        <li class="event" :data-date="record.releaseDateText">
          <h3>{{ record.period }}</h3>
          <p>{{ record.newstext }}</p>
        </li>
      </div>
    </ul>
    </div>
    <div class="col-2"></div>
  </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "NewsService",
  data () {
    return {
      showImages: false,
      records: []
    }
  },
  methods: {
    fetchRecords: function () {
      const baseURI = 'https://www.stateofbeing.com/api/news/read.php'
      axios.get(baseURI)
      .then((result) => {
        console.log(result.data);
        this.records = result.data.records
      })
    }
  },
  mounted() {
    this.fetchRecords();
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news-content {
  position: relative; 
  align-items: center;
  justify-content: center;
  background-image: url("https://www.stateofbeing.com/images/diskface-background-1394x1394.jpg");
  background-color: rgba(255,255,255,0.6);
  background-blend-mode: lighten;
}
.news {
  opacity: 1;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
