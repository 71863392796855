<template>
  <nav class="navbar navbar-dark navbar-expand-md fixed-top s-navbar">
    <router-link to="/" class="navbar-brand"><img src="https://www.stateofbeing.com/images/sob-logo-wht-122x125.png" alt="home" height="50"> STATE OF BEING</router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"><router-link to="/discog" class="nav-link">DISCOGRAPHY</router-link></li>
        <li class="nav-item"><router-link to="/about" class="nav-link">CONNECT</router-link></li>
      </ul>
    </div>
  </nav>

  <router-view/>

  <div class="footer">
    <div class="row social-row">
      <div class="social-list align-right pb-2">
        <div class="col-4 soc-item">
          <a href="https://twitter.com/stateofbeing" target="_blank">
            <span class="mbr-iconfont mbr-iconfont-social socicon-twitter socicon">twitter</span>
          </a>
        </div>
        <div class="col-4 soc-item">
          <a href="https://www.facebook.com/stateofbeing/" target="_blank">
            <span class="mbr-iconfont mbr-iconfont-social socicon-facebook socicon">facebook</span>
          </a>
        </div>
        <div class="col-4 soc-item">
          <a href="https://www.youtube.com/playlist?list=PLrX6DgSF4jbkf8M7xG8K7jxO0K-LcJSyD" target="_blank">
            <span class="mbr-iconfont mbr-iconfont-social socicon-youtube socicon">youtube</span>
          </a>
        </div>
      </div>
    </div>

    <div class="row row-copirayt">
      <p class="col-12 mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
        © Copyright 1994-2024 State of Being // Contact: sob@stateofbeing.com
      </p>
    </div>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

body { 
    padding-top: 65px; 
}

nav.s-navbar {
  padding: 30px;
  background-color: #444;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.s-nav-header {
  float: left;
}

.logo {
  float: left;
}

.title {
  float: left;
  flex-shrink: 0;
  height: 60px;
  line-height: 60px;
}

.s-nav-detail {
  flex-direction: row;
  float: right;
}

.s-nav-item {
  flex-shrink: 0;
  height: 60px;
  line-height: 60px;
}

nav a,
li.s-nav-item a {
  font-weight: bold;
  color: #ffffff;
  padding-right: 30px;
  display: block;
  color: white;
  text-align: center;
  /* padding: 14px 16px; */
  text-decoration: none;
  padding: 5px 15px;
}

/* Change the link color to #666 (black) on hover */
.s-nav-header .title a:hover,
li.s-nav-item a:hover {
  color: white;
  background-color: #666;
}

.footer {
  background-color: #DDD;
}
.social-row {
    width: 100%;
    justify-content: center;
}
.soc-item {
  display: inline;
}
.row-copirayt {
    word-break: break-word;
    width: 100%;
}
.row-copirayt p {
    width: 100%;
    text-align: center;
} 
</style>
